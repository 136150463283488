import React, { createContext, useContext, useState, useEffect} from "react";
import { meFn, refreshTokenRequest, getAssetsAvailability } from '../lib/api';
import { getCookie, deleteCookie, setCookie, setPersistedValue, getPersistedValue } from '../lib/utils';
import config from '../lib/config';
// create context
const UserContext = createContext();
  
const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [userToken, setUserToken] = useState(false);
    const [refreshToken, setRefreshToken] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [focusMode, setFocusMode] = useState(false);
    const [assetsAvailable, setAssetsAvailable] = useState(0);
    const [isGettingAssetAvailability, setIsGettingAssetAvailability] = useState(true);


    useEffect(() => {
        //we specify something different than just "token" to avoid conflicts with login token request paramente that con be a google token as well
        const urlParams = new URLSearchParams(window.location.search);
        const tokenRequestParameter = urlParams.get('u_t') ?? false;

        if(tokenRequestParameter){
            console.log("Token request parameter, overriding cookies and localstorage", tokenRequestParameter);
        }
        const token = getPersistedValue(config.sessionTokenLocalStorageKey, config.sessionTokenCookieKey, tokenRequestParameter);

        //console.log("GOT THIS TOKEN", token);
        if(!token){
            //console.log("no token to set");
            setUserToken(null);
            setUser(null);
            setIsAuthenticating(false);
        } else {
            //console.log("we have a token to set");
            setUserToken(token);
            setIsAuthenticating(true);
        }

        const refresh_token = getPersistedValue(config.refreshTokenLocalStorageKey, config.refreshTokenCookieKey);

        if(!refresh_token){
            //console.log("no refresh token");
            setRefreshToken(null);
        } else {
            //console.log("we have a refresh token to set");
            setRefreshToken(refresh_token);
        }

        window.refreshToken = async () => {
            const refresh_token = getPersistedValue(config.refreshTokenLocalStorageKey, config.refreshTokenCookieKey);

            if(refresh_token){
                let data;
                try{
                    data = await refreshTokenRequest(refresh_token);
                    if(data && data.access_token){
                        console.debug("Persist token and refresh after refres-token request response");
                        setPersistedValue(config.sessionTokenLocalStorageKey, config.sessionTokenCookieKey, data.access_token);
                        setPersistedValue(config.refreshTokenLocalStorageKey, config.refreshTokenCookieKey, data.refresh_token);
    
                        setUserToken(data.access_token);
                        setRefreshToken(data.refresh_token);
                        
                        //console.log("we got a refresh!");
                        return data.access_token;
                    }
                }catch(e){
                    //console.log("error while refreshing token", e);
                }
                deleteCookie(config.refreshTokenCookieKey);
                localStorage.removeItem(config.refreshTokenLocalStorageKey);
                //console.log("maybe the refresh token was expired..");
                return false;
            }else{
                return false;
                //await new Promise(resolve => setTimeout(resolve, 1000));
                //return await window.refreshToken();
            }
        };
    }, []);

    useEffect(() => {
        if(!userToken){
            return;
        }
        console.debug("use effect perssisting token");
        setPersistedValue(config.sessionTokenLocalStorageKey, config.sessionTokenCookieKey, userToken);
        //delay in case refreshToken is being stored...
        setTimeout(loadCurrentUser, 300);  
    }, [userToken]);

    useEffect(() => {
        if(!refreshToken){
            return;
        } 
        console.debug("use effect perssisting refresh token");
        setPersistedValue(config.refreshTokenLocalStorageKey, config.refreshTokenCookieKey, refreshToken);
    }, [refreshToken]);
    


    useEffect(() => {
        if(!user){
            return;
        }
        //console.log("USER", user);
        setIsAuthenticating(false);
        //Set a user creation tiemstamp as a cookie/localStorage
        if(user && user.created_at){
            let registrationTimestamp = new Date(user.created_at).getTime();
            localStorage.setItem('crateRegistration', `${registrationTimestamp}`);
            setCookie('crateRegistration', `${registrationTimestamp}`, 365);
        }

        //get assets availability
        getAssetsAvailability().then(data => {
            console.log("assets available", data);
            let remaining = 0;
            data.forEach(d => {
                if (d.name === 'assets-download') {
                    remaining = d.remaining;
                }
            });
            setAssetsAvailable(remaining);
            setIsGettingAssetAvailability(false);
        });
    }, [user]);

    const loadCurrentUser = async () => {
        setIsAuthenticating(true);
        try {
            let data = await meFn();
            data.premiumAssetsPolicy = false;
            data.isEnterprise = false;
            data.assetsPerDay = 0;

            const assetsFeature = data?.team?.subscription?.plan?.features?.find(feat => feat.name === 'assets-download');
            console.log({assetsFeature});
            if (assetsFeature) {
                data.assetsPerDay = +assetsFeature.charges;
                data.premiumAssetsPolicy = !assetsFeature.policy.name.toLowerCase().includes('free');
            }
            const usersOnTeam = data?.team?.subscription?.plan?.features?.find(feat => feat.name === 'users-on-team');
            if (usersOnTeam) {
                data.isEnterprise = usersOnTeam.charges > 1 ? usersOnTeam.charges : false;
            }
            setUser(data);

        } catch(err) { 
            //console.log("User Authentication ERROR", err);
            setUserToken(null);
            setIsAuthenticating(false);
        };
    };

    const unloadUser = () => {
        setPersistedValue(config.sessionTokenLocalStorageKey, config.sessionTokenCookieKey, null);
        setPersistedValue(config.refreshTokenLocalStorageKey, config.refreshTokenCookieKey, null);
        setUserToken(null);
        setRefreshToken(null);
        setUser(null);
    };

    return (
        // the Provider gives access to the context to its children
        <UserContext.Provider
          value={{
            user,
            userToken,
            isAuthenticating,
            setUser,
            setUserToken,
            setRefreshToken,
            reloadCurrentUser: loadCurrentUser,
            unloadUser,
            focusMode,
            setFocusMode,
            assetsAvailable,
            isGettingAssetAvailability,
          }}
        >
          {children}
        </UserContext.Provider>
      );
};

const useUserContext = () => useContext(UserContext);

export { useUserContext, UserContextProvider };

