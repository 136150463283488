import client from './axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, deleteCookie, setCookie, getPersistedValue, setPersistedValue } from './utils';


export const forgetPassword = async (data) => {
  try{
    const response = await client.post('auth/forgot-password', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await client.post('auth/reset-password',  data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUserFn = async (user) => {
  try {
    const response = await client.post('auth/login', user);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const refreshTokenRequest = async (refresh_token) => {
  try {
    const response = await client.post('auth/refresh-token', {refresh_token});
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const deleteUserFn = async () => {
  const response = await client.delete('me')
  return response.data
}


export const subscribeUserFn = async ({ provider, planId }) => {
  const response = await client.post('subscriptions', {
    provider,
    plan_id: planId,
  })
  if(response && response.data){
    return response.data
  }
  console.log("bad response from subscribe [provider, planId, response]", provider, planId, response);
  return false;
}

export const cancelSubscription = async () => {
  const response = await client.delete('subscriptions');
  if(response && response.data){
    return response.data;
  }
  console.log("bad response from cancel subscription", response);
  return false;
  
}

export const getProducts = async ({ campaign }) => {

  let url = '/products';
  if(campaign){
      url += `?campaign_id=${campaign}`;
  }
  const response = await client.get(url);

  if(response && response.data){
    return response.data;
  }
  return false;
}


export const favoritesFn = async (data) => {
  let response = {};
  let favorites = [];

  if(data){
    response = await client.get('me');
    if(response.data.settings && response.data.settings['favorites']){
      favorites = JSON.parse(response.data.settings['favorites']);
    }
    if(favorites.filter(item => item.id === data.id).length === 0){
      favorites.push(data);
      response = await client.put('me/settings', {favorites: JSON.stringify(favorites)});

      return response.data;
    }
    return favorites;

  }else{
    response = await client.get('me');
    if(response.data.settings && response.data.settings['favorites']){
      favorites = JSON.parse(response.data.settings['favorites']);
    }
    return favorites;
  }
}

export const favoritesDelete = async id => {
  let favorites = await favoritesFn();
  favorites = favorites.filter(item => item.id !== id);
  const response = await client.put('me/settings', {favorites: JSON.stringify(favorites)});
  return favorites;
}

export const userFn = async (data) => {
  let response = {};
  try{
    if(data){
      response = await client.put('me', data);
    }else{
      response = await client.get('me');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const meFn = async (data) => {
  let response = {};
  try{
    if(data){
      response = await client.put('me/settings', data);
    }else{
      response = await client.get('me');
    }
    return response.data;
  } catch (error) {
    console.log("Throw error", error.message);
    //throw error;
  }
}

export const expireAllSessions = async () => {
  let response = {};
  try{
    response = await client.post('me/expire-session');
    return response.data;
  } catch (error) {
    console.log("Throw error", error.message);
    //throw error;
  }
}



/*
Members (GET): Listado de miembros del teams al cual pertenece el user
Delete Member (DELETE): Borra un miembro por users_id (solo el owner puede borrar, no puedo borrarse a si mismo)
Invitations
Invitations (GET): Invitaciones enviadas
Invite (POST): Crea una nueva invitación (esto no crea usuarios)
Delete invitation (DELETE)
Resend Invitation (GET): Reenvia el mail de invitación
Accept Invite (GET): Este método lo usas para confirmar una invitación. Tenes que mandar el token que lo recibis como parametro desde el mail. 
*/

export const teamFn = {
  settings: async (data) => {
    let response = {};
      if(data){
        response = await client.put('team/settings', data);
      }else{
        response = await client.get('team/settings');
      }
    return response.data
  },
  members: {
    list: async () => {
      let response = {};
      response = await client.get('team/members');
      return response.data
    },
    delete: async (id) => {
      let response = await client.delete(`team/members/${id}`);
      return response.data
    },
  },
  invitations: {
    list: async () => {
      let response = {};
      response = await client.get('team/invitations');
      return response.data
    },
    delete: async (id) => {
      let response = await client.delete(`team/invite/${id}`);
      return response.data
    },
    invite: async (email) => {
      let response = await client.post(`team/invite`, {email});
      return response.data
    },
    resend: async (id) => {
      let response = await client.get(`team/invite/resend/${id}`);
      return response.data
    },
    accept: async (key) => {
      let response = await client.get(`team/invite/accept/${key}`);
      return response.data
    },
    getEmail:  async (key) => {
      let response = await client.get(`team/invite/get-email/${key}`);
      return response.data
    }
  }


}

/*
export const teamFn = async (data) => {
  let response = {};
    if(data){
      response = await client.put('team/settings', data);
    }else{
      response = await client.get('team/settings');
    }
  return response.data
}
*/

export const getInvoices = async () => {
  const response = await client.get('invoices')
  return response.data
}

export const getAssetsAvailability = async () => {
  const response = await client.get('assets/remaining')
  return response.data
}

export const getAssetsConsumptions = async () => {
    const response = await client.get('assets/consumptions')
    return response.data
}

export const consumeAssets = async (data) => {
    const response = await client.post('assets/consume', data);
    
    return response.data;
}

export const resendVerificationEmail = async () => {
  const response = await client.get('/email/resend');
  return response.data;
}

export const searchAssets = async (search, search_type=false, newUrlScheme) => {
    var query = search
    if(query !== '') {
      query = encodeURIComponent(query).replace(/%20/g, "+");
    } else {
      query = 'new';
    }
    
    var search_url = 'https://vfx.productioncrate.com/search/#!/'+query;
    
    if (newUrlScheme && search_type !== 'tutorials') {
      search_url = `https://app.productioncrate.com/search/${query}?main_category=${search_type.replace('sounds', 'sfx')}`;
    } else if(search_type === 'news') {
      search_url = 'https://news.productioncrate.com/?s='+query;
    } else if(search_type === 'scripts') {
      search_url = 'https://www.productioncrate.com/search/#!/'+query;//+'&incoming=header';
    } else if(search_type === 'tutorials') {
      search_url = 'https://news.productioncrate.com/?s='+query+'&category_name=how-to-guides';//&incoming=header';
    } else if(search_type === 'models') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=models';//&incoming=header';
    } else if(search_type === 'materials') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=materials';//&incoming=header';
    } else if(search_type === 'environments') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query+'&render_type=environments';//&incoming=header';
    } else if(search_type === '3d') {
      search_url = 'https://www.productioncrate.com/3D/search/#!/'+query;
    } else if(search_type === 'vfx') {
      search_url = 'https://vfx.productioncrate.com/search/#!/'+query;//+'&incoming=header';
    } else if(search_type === 'mograph') {
      search_url = 'https://vfx.productioncrate.com/search/#!/'+query+'&video_type=1';//&incoming=header';
    } else if(search_type === 'sounds') {
      search_url = 'https://sfx.productioncrate.com/search/#!/'+query+'&audio_type=0';//&incoming=header';
    } else if(search_type === 'music') {
      search_url = 'https://sfx.productioncrate.com/search/#!/'+query+'&audio_type=1';//&incoming=header';
    } else if(search_type === 'graphics') {
      search_url = 'https://graphics.productioncrate.com/search/#!/'+query;//+'&incoming=header';
    }
    window.location.href = search_url;
    return false;
    //let res = await fetch(`https://d30upb8d426d8z.cloudfront.net/search-json/fc/query/${search.replace(" ", "+")}/order_by/new/broadness/1/v/2/`);
    //return await res.json();
}
export const providerLoginFn = async (provider, credentials) => {
  let url = `auth/${provider}/login`;
  if(provider === 'email'){
      url = `auth/register`;
  }
  try{
    let hasReferral = getCookie('referral');
    if(hasReferral){
      let reference = JSON.parse(decodeURIComponent(hasReferral));

      credentials['referral'] = reference['referral'] || '';
      credentials['referral_data'] = reference['referral_data'] || {};
    }

    const response = await client.post(url, credentials);
    return response.data;
  } catch (error) {
    console.log("RESPONSE", error);
    throw error;
  }
}

export const formatErrorResponse = (response) => {
  return response?.data?.error ?? response?.data?.message ?? response?.statusText ?? 'Something went wrong'
}

export const deleteFaultyCookies = () => {
  try {
    if(
      getCookie("cr_sess_refresh_local") ||
      getCookie("cr_sess_local") ||
      localStorage.getItem("cr_sess_refresh_local") ||
      localStorage.getItem("cr_sess_local")
    ){  
      console.log("user has faulty cookies, deleting...");
      deleteCookie("cr_sess_refresh_local");
      deleteCookie("cr_sess_local");
      localStorage.removeItem("cr_sess_refresh_local");
      localStorage.removeItem("cr_sess_local");
      Analytics.sendCustomEvent('deleteFaultyCookies');
    }
  } catch (error) {
    console.error("Error trying to delete cookies", error);
  }
  
};

const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";

const safeExecute = async (fn, errorMessage) => {
  try {
    return await fn();
  } catch (error) {
    console.error(errorMessage, error);
  }
};

const partnerStackSignUp = data => {
  safeExecute(() => {
    if (typeof window.growsumo !== 'object') {
      console.log("Partner Stack wasn't initialized.");
      return;
    }
    let hasReferral = getCookie('referral');
    if (!hasReferral) {
      console.log("Signup without partnerstack referrer.");
      return;
    }
    window.growsumo.data = {
      name: data.name || '',
      email: data.email || '',
      customer_key: `${data.team_id || ''}`
    };
    window.growsumo.createSignup();
    deleteCookie('referral', '.productioncrate.com');
  }, "Error in partnerStackSignUp");
};

const sendGAEvent = (event_name, paid_amount, additional_data = {}) => {
  safeExecute(() => {
    if (getEnvironment() === 'development') {
      console.log('event', event_name, {
        'send_to': 'G-78KP68EXMW',
        'value': paid_amount,
        'currency': 'USD',
        ...additional_data
      });
      return;
    }
    if (window.gtag) {
      window.gtag('event', event_name, {
        'send_to': 'G-78KP68EXMW',
        'value': paid_amount,
        'currency': 'USD',
        ...additional_data
      });
    }
  }, "Error in sendGAEvent");
};

const sendConversion = (send_to, paid_amount) => {
  safeExecute(() => {
    if (getEnvironment() === 'development') {
      console.log('event', 'conversion', {
        'send_to': send_to,
        'value': paid_amount,
        'currency': 'USD'
      });
      return;
    }
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': send_to,
        'value': paid_amount,
        'currency': 'USD'
      });
    }
  }, "Error in sendConversion");
};

const dataLayerPush = (event_name, data) => {
  safeExecute(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'event': event_name, ...data });
  }, "Error in dataLayerPush");
};

export const Analytics = {
  anonymousId: null,

  init: () => {
    let existingId = getPersistedValue('cr_aid', 'cr_aid');
    if (!existingId) {
        existingId = uuidv4();
        setPersistedValue('cr_aid', 'cr_aid', existingId);
    }
    Analytics.anonymousId = existingId;
  },

  sendCustomEvent: (event, metadata={}) => {
    return safeExecute(async () => {
      if (!Analytics.anonymousId) {
        Analytics.init();
      }
      const anonymous_id = Analytics.anonymousId;

      console.debug("Analytics.sendCustomEvent - Anonymous ID:", anonymous_id);
      await client.post('analytics/event', {
        event,
        anonymous_id,
        metadata: {
          path: window.location.pathname,
          host: window.location.hostname,
          referrer: document.referrer,
          user_agent: navigator.userAgent,
          screen_resolution: `${window.screen.width}x${window.screen.height}`,
          viewport_size: `${window.innerWidth}x${window.innerHeight}`,
          ...metadata
        }
      });
    }, "Error in Analytics.sendCustomEvent: "+event);
  },
  view: (name, metadata={}) => {
    return Analytics.sendCustomEvent(`view_${name}`, metadata);
  },
  click: (name) => {
    return Analytics.sendCustomEvent('click', {target: name});
  },

  signup: (paid_amount = 0, userData) => {
    safeExecute(() => {
      sendConversion('AW-709072149/9-m3CK6M-a8YEJWqjtIC', paid_amount);
      sendGAEvent('sign_up', paid_amount);
      partnerStackSignUp(userData);
      Analytics.sendCustomEvent('signup', {paid_amount, ...userData});
    }, "Error in Analytics.signup");
  },
  addToCart: (paid_amount = 0, plan_name = "") => {
    safeExecute(() => {
      sendConversion('AW-709072149/wmV6CLLtgbAYEJWqjtIC', paid_amount);
      sendGAEvent('plan_selection', paid_amount);
      Analytics.sendCustomEvent('addToCart', {paid_amount, plan_name});
    }, "Error in Analytics.addToCart");
  },
  conversion: (paid_amount = 0, subscriptionId = false) => {
    safeExecute(() => {
      sendConversion('AW-709072149/FZ5WCNL8768YEJWqjtIC', paid_amount);
      sendGAEvent('purchase', paid_amount, {
        'currency': 'USD',
        'signed_on': true,
        'paying_member': true,
        'page_title': 'New Pro Member'
      });
      dataLayerPush('purchaseConversion', {
        'conversionValue': paid_amount,
        'subscriptionId': subscriptionId
      });
      Analytics.sendCustomEvent('conversion', {paid_amount, subscriptionId});
    }, "Error in Analytics.conversion");
  }
};


