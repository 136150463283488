import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import md5 from 'js-md5';
import { useUserContext } from "../../contexts";
import { searchAssets, subscribeUserFn, meFn, Analytics} from '../../lib/api';
import useClickable from '../../hooks/useClickable';
import Menu from './Menu';
import Logo from './logo.svg';
import LogoName from './logo.name.svg';
import LogoIcon from './logo.icon.svg';
import { Spinner } from '../Spinner';
import Survey from '../Survey/Survey';
import './Banner.scss';
import CATEGORIES from './categories.json';


const BASE_API_MAP = {
    'development': 'http://crate-core.com',
    'qa': 'https://rest-qa.productioncrate.com',
    'production': 'https://rest.productioncrate.com',
    'webapp': 'https://rest.productioncrate.com'
}
const getEnvironment = () => {
    if (window.location.hostname==='app.productioncrate.com') {
        return 'webapp';
    }
    return window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";
};
const env = getEnvironment();



const Banner = () => {

    const {user, unloadUser, focusMode, isAuthenticating, assetsAvailable} = useUserContext();
    const [banners, setBanners] = useState([]);
    const [bannerToDisplay, setBannerToDisplay] = useState(null);
    
    const baseUrl = BASE_API_MAP[env];

    useEffect(() => {
        fetch(`${baseUrl}/web/banners`)
        .then(response => response.json())
        .then(data => {
            setBanners(data);
        });
    }, []);

    useEffect(() => {
        //console.log("BANNER EFFECTS");
        if(!banners || banners.length === 0){
            //console.log("no banner to show");
            return;
        }
        
        if(!user && isAuthenticating){
            //console.log("user is authenticating...")
            return;
        }
        //console.log("banner user:", user);
        let seenBanners = {};

        try {
            seenBanners = JSON.parse(user?.settings?.banners || '{}');
        } catch (e) {
            //console.log("Problem parsing banners", e);
            return;
        }

        let newBanners = banners.filter(banner => !seenBanners.hasOwnProperty(+banner.id));
        //console.log("newBanners", newBanners);

        //blacklist urls where we don't want to display banners
        const blacklist = ["register", "upgrade", "signup"];
        if(blacklist.some(substring => window.location.href.includes(substring))){
            //console.log("blacklist return");
            return;
        }

        newBanners = newBanners.filter(banner => {
            if (!user && banner.tags.some(tag => ["cohort_a", "cohort_b", "old_user"].includes(tag))) {
                //console.log("returning false because user is not logged in", banner);
                return false;
            }
            if(banner.tags.includes("cohort_a") && user.id%2 !== 0){
                //console.log("returning false because user is not in cohort_a", banner);
                return false;
            }
            if(banner.tags.includes("cohort_b") && user.id%2 === 0){
                //console.log("returning false because user is not in cohort_b", banner);
                return false;
            }
            if(banner.tags.includes("old_user")){
                const createdAt = new Date(user.created_at);
                const monthsAgo = new Date();
                monthsAgo.setMonth(monthsAgo.getMonth() - 12);
                if(createdAt >= monthsAgo){
                    //console.log("returning false because user is not an old user", createdAt, monthsAgo);
                    return false;
                }
            }
            if(banner.tags.includes("always_free") && !user.always_free){
                //console.log("returning false because user wasn't always free", user);
                return false;
            }
            if(user && user.team && user.team.subscription && user.team.subscription.plan.price > 0 && !banner.tags.includes("all_logged_in_users")){
                //console.log("user has a non-free plan")
                return false;
            }
            return true;
        });

        if(newBanners.length === 0){
            return;
        }
        let bannerTemp = newBanners[0];
        if(bannerTemp.message && bannerTemp.message.includes("{{user}}")){
            bannerTemp.message = bannerTemp.message.replace("{{user}}", printUserAName());
        }

        setBannerToDisplay(bannerTemp);
        
    }, [user, isAuthenticating, banners]);
    useEffect(() => {
        if (!bannerToDisplay) {
            return;
        }
        Analytics.view('banner', {target: `banner_${bannerToDisplay.title.toLowerCase().replace(/ /g, "_")}`});
    }, [bannerToDisplay]);
    const hideBanner = (id) => {
        setBannerToDisplay(null);
        let seenBanners = {};
        try{
            seenBanners = user.settings && user.settings.banners?JSON.parse(user.settings.banners):{};
        }catch(e){
            //console.log("Problem parsing banners", e);
        }
        seenBanners[+id] = true;
        meFn({banners: JSON.stringify(seenBanners)});
        Analytics.sendCustomEvent(`close_banner`, {target: `${bannerToDisplay.title.toLowerCase().replace(/ /g, "_")}`});
    };

    const printUserAName = () => {
            
        if (user.first_name && user.first_name!=='no_firstname') {
            return user.first_name;
        }
        if(user.username === user.email){
            return "valued user";
        }

        return user.username;
    };

    const handleBannerClick = () => {
        Analytics.click(`banner_${bannerToDisplay.title.toLowerCase().replace(/ /g, "_")}`).finally(() => {
            document.location.href = bannerToDisplay.url;
        });
    };
    return (
        <>
            {bannerToDisplay &&
            <div className="banner_web">

                {bannerToDisplay.image && bannerToDisplay.image.url &&
                <img src={bannerToDisplay.image.url} className="banner_pic" alt="banner" />
                }
                {!bannerToDisplay.image &&
                <span className="content">
                    {bannerToDisplay.url && 
                    <div onClick={handleBannerClick} >
                        {bannerToDisplay.message}
                    </div>
                    }
                    {!bannerToDisplay.url &&
                    <>
                        {bannerToDisplay.message}
                    </>
                    }
                </span>
                }
                <span className="close" onClick={() => hideBanner(bannerToDisplay.id)}>X</span>
            </div>
            }
        </>
    );
};

export default Banner;

