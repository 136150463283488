import React, { useState, useEffect } from 'react';
import './ParticleSystem.css';

const ParticleSystem = ({ children }) => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const particle = {
        id: Date.now(),
        left: Math.random() * 100,
        top: 100, // Start just outside the bottom of the target element
      };
      setParticles(prevParticles => [...prevParticles, particle]);

      setTimeout(() => {
        setParticles(prevParticles =>
          prevParticles.filter(p => p.id !== particle.id)
        );
      }, 2000);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='particle-system-wrapper'>
      {children}
      <div className='particles'>
        {particles.map(particle => (
          <div
            key={particle.id}
            className='particle'
            style={{ left: `${particle.left}%`, top: `${particle.top}%` }}
          />
        ))}
      </div>
    </div>
  );
};

export default ParticleSystem;
