import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { SortBy, InstantSearch, SearchBox, InfiniteHits, Configure, RefinementList, ClearRefinements, useSearchBox, useSortBy } from 'react-instantsearch';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import 'instantsearch.css/themes/satellite.css';

import { searchAssets } from '../../lib/api';
import useClickable from '../../hooks/useClickable';

import Logo from './logo.svg';
import { Spinner } from '../Spinner';

import './SearchBar.scss';

const VERTICAL_NAMES = {
    'videos': 'Footage',
    'sounds': 'Sounds',
    '3d': '3D',
    'graphics': 'Graphics',
    'tutorials': 'Tutorials'
};

const BASE_URL_MAP = {
    'development': 'http://crate-core.com',
    'qa': 'https://user-qa.productioncrate.com',
    'production': 'https://user.productioncrate.com',
    'webapp': 'https://user.productioncrate.com'
}

const getEnvironment = () => {
    if (window.location.hostname==='app.productioncrate.com') {
        //console.log("ENV", "webapp");
        return 'webapp';
    }
    const env = window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";
    return env;
};
const env = getEnvironment();

const { searchClient } = instantMeiliSearch(
    'https://find.productioncrate.com/',
    'd0809db0dd5a70fdb970703a355dfd5421d85447a76d370b9b4b7c406d0d109f',
    {
        placeholderSearch: true,
    }
  );
  
const Hit = ({ hit }) => (
    <React.Fragment key={hit.id}>
        {hit.vertical === 'videos' && 
        <>
            <video className="video_pic"  autoplay="" muted playsinline="" loop poster="" preload="auto">
                <source src={`https://p.productioncrate.com/stock-hd/effects/${hit.location}-s.mp4`} type="video/mp4" />
            </video>
            {hit.level === 1 &&
            <span className="asset_level glyphicon glyphicon-star" />
            }
            {/*
            <div className="asset_created" >{hit.created_at}</div>
            <div className="asset_popularity" >{hit.downloads_ranking?.month > 1000 ? `${(hit.downloads_ranking?.month/1000).toFixed(1)}K` : hit.downloads_ranking?.month}</div>
            */}
            <div className="asset_name" >{hit.title}</div>
        </>
        }
        {hit.vertical === '3d' &&
        <>
        <img 
            src={`https://p.productioncrate.com/stock-hd/objects/${hit.location}/back.jpg`} 
            className='video_pic' 
            alt={hit.title} 
        />
        <div className="asset_level" >{hit.level === 1?'⭐':''}</div>
        <a href={hit.location} >{hit.title}</a>
        </>
        }
        {hit.vertical === 'sounds' &&
        <>
        <img
            src={`https://p.productioncrate.com/stock-hd/audio/${hit.location}-c.png`}
            className='video_pic'
            alt={hit.title}
        />
        <audio controls className='video_pic'>
            <source src={`https://p.productioncrate.com/stock-hd/audio/${hit.location}.mp3`} type="audio/mpeg" />
        </audio>
        <div className="asset_level" >{hit.level === 1?'⭐':''}</div>
        <a href={hit.location} >{hit.title}</a>
        </>
        }
        {hit.vertical === 'graphics' &&
        <>
        <img
            src={`https://p.productioncrate.com/stock-hd/graphics/${hit.category_slug}/${hit.location}_prev_sm.png`}
            className='video_pic'
            alt={hit.title}
        />
        <div className="asset_level" >{hit.level === 1?'⭐':''}</div>
        <a href={hit.location} >{hit.title}</a>
        </>
        }
        
    </React.Fragment>
);

const CustomSearchBox = ({onFocus, handleClose, clearResult}) => {
    const { refine } = useSearchBox();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchFocused, setSearchFocused] = useState(false);


    const handleFocus = () => {
        setSearchFocused(true);
        onFocus();
    };

    const handleClear = () => {
        setSearchQuery('');
        refine('');
        clearResult && clearResult();
        setTimeout(handleClose, 350);
    }
    return (
        <>
        <input 
            type="text" 
            value={searchQuery} 
            
            onChange={(e) => {
                setSearchQuery(e.target.value);
                refine(e.target.value);
            }}
            onFocus={handleFocus} 
            onBlur={() => {
                if(!searchQuery ){ 
                    setSearchFocused(!!searchQuery);
                }
            }} 
            name="query" 
            id="header-search-bar-input"
            placeholder={searchFocused?`Try searching "new" to see recent uploads`:'Search'}
            spellCheck="True" 
            autoComplete="off" 
            dir="auto" 
            className={`search-bar-input`}
        />
        <span 
            onFocus={() => setSearchFocused(true)} className={`close glyphicon glyphicon-cross ${searchQuery?'visible':''}`} 
            onClick={handleClear} 
        />
        </>
    );
  };

const SetDefaultSort = () => {
    const { refine } = useSortBy();

    useEffect(() => {
        setTimeout(() => refine('assets:created_at:desc'), 0); // Forzar el orden por defecto
    }, []);

    return null;
};

const SearchBar = ({external=false, newUrlScheme=false}, ref) => {

    
    const [searchQuery, setSearchQuery] = useState('');
    const [searchFocused, setSearchFocused] = useState(false);
    
    const [categorySelected, setCategorySelected] = useState('all');
    

    const [hideSearchResults, setHideSearchResults] = useState(false);



    const baseUrl = BASE_URL_MAP[env];
    const baseHref = external?baseUrl:'';



    const handleSearch = (event) => {
        event.preventDefault();

        searchAssets(searchQuery, categorySelected, newUrlScheme);
        //setSearchQuery('');
    };

    const handleShow = () => {
        setHideSearchResults(false);
        setSearchFocused(true);
    };
    const handleClose = () => {
        setHideSearchResults(true);
        setSearchFocused(false);
    };

    useEffect(() => {
        if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical sounds)");
            setCategorySelected('sounds');
        } 
        else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical 3d");
            setCategorySelected('3d');
        }
        else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical vfx");
            setCategorySelected('vfx');
        }
        else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical graphics");
            setCategorySelected('graphics');
        }
        let path = window.location.pathname;
        if (path.includes('/search')) {
            let searchTerm = '';
            if (window.location.hash) {
                if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical sounds)");
                    setCategorySelected('sounds');
                } 
                else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical 3d");
                    setCategorySelected('3d');
                }
                else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical vfx");
                    setCategorySelected('vfx');
                }
                else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical graphics");
                    setCategorySelected('graphics');
                }
                
                searchTerm = window.location.hash.split('#!/')[1].split('&')[0];
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const mainCategory = urlParams.get('main_category');
                if (['all', 'vfx', 'sounds', '3d', 'graphics'].some(c => c===mainCategory)) {
                    setCategorySelected(mainCategory);
                }
                searchTerm = path.split('/search/')[1].split('?')[0];
            }
            searchTerm.replace('+', ' ');
            if (searchTerm.length>0){
                console.log("SEARCH TERM", searchTerm, searchTerm.replace("+", " "));
                setSearchQuery(searchTerm);
                setSearchFocused(true);
            }
        }
    }, []);
    return (
        <InstantSearch
            indexName="assets"

            searchClient={searchClient}
            style={{position: 'absolute', top: '50px'}}
        >
            <Configure hitsPerPage={30} />
            <div className={`search-component ${searchFocused?'focused':''}`} >
                <div className="shadow" onClick={handleClose} />
                <button className="btn btn_close" style={{}} onClick={handleClose}>
                    <span className="close glyphicon glyphicon-cross" />
                </button>
                <form className={`search-bar_2 ${searchFocused?'focused':''} m-l-a`} onSubmit={handleSearch} >
                    <img className="logo-search" src={`${baseHref}${Logo}`} alt="Logo" />
                        {/*onChange={({target}) => setSearchQuery(target.value)}*/} 
                        <CustomSearchBox 
                            onFocus={handleShow}
                            onClose={handleClose}
                        />
                        <button type="submit" className="form-btn btn-search">
                            <span className="glyphicon glyphicon-search" />
                        </button>   
                </form>
                    <div className={`search-modal ${hideSearchResults?'hide':''}`}>
                        
                        <div className="search-filters">
                            <SortBy 
                                items={[
                                    { label: 'Sort By', value: '' },
                                    { label: 'New', value: 'assets:created_at:desc' },
                                    { label: 'Popular', value: 'assets:downloads_ranking.month:desc' },
                                    { label: 'Popular Historical', value: 'assets:downloads_ranking.year:desc' },
                                ]} 
                                classNames={{
                                    root: 'filters-sortby',
                                    select: 'filters-sortby--select',
                                }}
                            />
                            Level:
                            <RefinementList 
                                operator="or"
                                attribute="level" 
                                transformItems={items => {
                                    const allLevels = ['0', '1']; // todos los niveles posibles
                                    return allLevels.map(level => {
                                    const item = items.find(i => i.label === level);
                                    return item 
                                        ? { ...item, label: level === '1' ? 'Pro' : 'Free' }
                                        : { label: level === '1' ? 'Pro' : 'Free', value: level, count: 0, isRefined: false };
                                    });
                                }}
                            />
                            Type:
                            <RefinementList 
                                operator="or"
                                attribute="vertical" 
                                transformItems={items => {
                                    const allVerticals = Object.keys(VERTICAL_NAMES);
                                    return allVerticals.map(key => {
                                    const item = items.find(i => i.label === key);
                                    return item 
                                        ? { ...item, label: VERTICAL_NAMES[key] }
                                        : { label: VERTICAL_NAMES[key], value: key, count: 0, isRefined: false };
                                    });
                                }}
                            />
                            Category:
                            <RefinementList 
                                operator="and"
                                attribute="category" 
                                showMore={true}
                                searchableIsAlwaysActive={false}
                                searchable={false}
                                searchablePlaceholder={'Filter categories'}
                                classNames={{
                                    root: 'filters-list--scrollable',
                                    showMore: 'filters-show-more',
                                    disabledShowMore: 'filters-show-more--disabled',
                                
                                }}
                                
                            />
                            <ClearRefinements 
                                classNames={{
                                    root: 'clear-filters',
                                    button: 'filters-clear',
                                }}
                            />
                        </div>
                        <InfiniteHits 
                            showPrevious={false} 
                            classNames={{
                                root: 'search-results-container',
                                list: 'search-results',
                                item: 'search-item',
                                loadMore: 'search-load-more',
                                disabledLoadMore: 'search-load-more--disabled',
                                emptyRoot: 'search-no-results',

                            }}  
                            hitComponent={Hit}
                            showMoreComponent={({ hasMore, refineNext }) => 
                                hasMore ? (
                                    <button onClick={refineNext} >Show More</button>
                                ) : null
                            }
                        />
                    </div>
                
            </div>
        </InstantSearch>
    );              
};

export default forwardRef(SearchBar);

