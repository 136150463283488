import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUserContext } from "../../contexts";
import { meFn, subscribeUserFn, Analytics, getProducts } from '../../lib/api';
import { Signup } from './';
import { Spinner } from '../Spinner';
import { getCookie } from '../../lib/utils';

import './OnlySignup.scss';

const OnlySignup = () => {

  //Custom Hooks
  const {user, isAuthenticating, unloadUser} = useUserContext();

  const [searchParams] = useSearchParams();
  let { planId, provider} = useParams();

  //State to control the loading spinner
  const [showLoading, setShowLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [ctaAfterSubscribe, setCtaAfterSubscribe] = useState('');

  const signupSuccessCallback = () => {
    setShowLoading(true);
    if(planId){
        console.log("Plan selected, subscription will happen");
    }else{
        console.log("No plan selected, nothing to do");
    }
  }
  

  const subscribeUser = async () => {
    let products = [];
    let plan = null;
    let campaign = searchParams.get("c")??false;
    try{
      products = await getProducts({campaign});
    }catch(e){
      console.log("Problem while getting products", e);
    }

    products.forEach(prod => {
        if(plan!==null){
            return;
        }
        prod.plans.forEach(p => {
            console.log(p.id, planId);
            if(+p.id === +planId){
                plan = p;
                return;
            }
        });
    });

    if (user && user.team && plan) {
        try{
          Analytics.signup(plan.price, {
              name: `${user.first_name || ''} ${user.last_name || ''} (${user.username || ''})`,
              email: user.email,
              team_id: user.team.id
          });
        }catch(e){
            console.log("Problem with analytics signup event", e);
        }
    }else{
        console.log("No user or plan found, nothing to do", user, planId, plan);
        return;
    }

    
    let postData = {provider:provider==='s'?'stripe':'paypal', planId};

    if(plan && plan.price === 0){
        //free registration, nothing to do but get redirected to member area
        try{
          Analytics.goalPlan(user.team.subscription.plan);
        }catch(e){
          console.log("Problem with analytics goalPlan event", e);
        }

        setTimeout(() => document.location.href='/member', 1000);
        return;
    }
    //console.log("State before subscribing: (doantor, provider, planId)", donatorCheck, provider, selectedPlan);
    subscribeUserFn(postData).then(data => {
      if(data.url && data.type !=='success'){
          //TODO: Somehow this subscribeTo settings is not cleaning out...
          console.log("REDIRECT TO URL", data);
          if (user.settings?.subscribeTo?.planId){
            meFn({subscribeTo: null}).then( r => document.location.href = data.url );
          } else {
            document.location.href = data.url;
          }
      } else if(data.type === 'success' && data.url) {
          console.log("SUCCESS & url", data);
          if (user.settings?.subscribeTo?.planId){
            meFn({subscribeTo: null}).then( r => document.location.href = data.url );
          } else {
            document.location.href = data.url;
          }
      }else if(data.type ==='success') {
        console.log("SUCCESS", data);
        setShowLoading(false);
      }else if(data.type === 'verify_email'){
        meFn({
          subscribeTo: {
            planId: planId,
            provider: provider,
            campaign: campaign
          }
        }).then(d => {
          setShowLoading(false);
          setVerificationError(data.msg);
          setCtaAfterSubscribe(data.type);
        });
      }else if(data.msg){
          console.log("ERROR OR WARNING", data);
          setShowLoading(false);
          setVerificationError(data.msg);
          setCtaAfterSubscribe(data.type);
      }
    })
    .catch(e => {
        console.log("Problem while subscribing user", e);
        setVerificationError("There was a problem while subscribing you to the plan.");
        setShowLoading(false);
        setCtaAfterSubscribe('error');
    });

  };

  const closeCallback = () => {
    //a react function for history back
    window.history.back();
  };

  useEffect(() => {  
    if(!isAuthenticating && !user){
        console.log("HIDE LOADING");
        console.log("is not authenticating: show loading off");
        setShowLoading(false);
    }else if(isAuthenticating){
        console.log("SHOW LOADING");
        setShowLoading(true);
    }
  }, [isAuthenticating]);

  useEffect(() => {
    if(user && planId){
      console.log("susbcribe user: show loading on");
      setShowLoading(true);
      subscribeUser();
    }
  }, [planId, user]);

  useEffect(() => {
    console.log("PLan to subscribe to", planId);
  }, [planId]);

  const handleTryDifferentEmail = () => {
    unloadUser();
    setTimeout(() => window.location.reload(), 100);
  }

  return (
    <>
      {(showLoading || isAuthenticating) && 
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: '100vh'}}>
          <Spinner style={{position: 'relative'}} />
      </div>
      }
      {!showLoading && verificationError && 
      <div className="form" style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: '100vh', flexDirection: 'column', gap: '20px'}}>
          <div className="title m-b-16" style={{color: '#FFF', fontSize: '18px'}}>{verificationError}</div>
          {ctaAfterSubscribe === 'error' &&
          <div style={{display: 'flex', gap: '16px', alignItems: 'center'}}>
            <div onClick={handleTryDifferentEmail} className="cta modern white">Try a different Email</div>
            <a href="/register" className="cta modern accent">View other Plans</a>
          </div>
          }
          {ctaAfterSubscribe === 'verify_email' &&
          <div style={{display: 'flex', gap: '16px', alignItems: 'center'}}>
            <div style={{border: 'none', fontSize: '16px', fontWeight: '500', color: '#FFF'}} >Already verified?: </div>
            <div onClick={() => window.location.reload()} className="cta modern accent">Reload and Continue</div>
          </div>
          } 
      </div>
      }
      {!showLoading && !user && 
      <Signup signupSuccessCallback={signupSuccessCallback} planId={planId} provider={provider} closeCallback={closeCallback} notClosable={true} />
      }
    </>
  );
};

export default OnlySignup;
